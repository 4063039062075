export const sellOrdersDummy = [
    {
        action: "buy",
        amount: 1,
        buySymbol: "ETH",
        createdAt: "2020-09-14T16:15:18.907Z",
        fee: 0,
        filledAmount: 0.8,
        inQueue: false,
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "partial-filled",
        type: "limit",
        unitPrice: 0.035,
        updatedAt: "2020-09-14T16:15:18.907Z",
        userId: "5f37ba620d9ebc2ef630a5bb",
        __v: 0,
        _id: "5f5f971625fe1764de5a6841",
    },
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    }
    ,
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    }
    ,
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    },
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    },
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    }
    ,{
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    },
    {
        action: "buy",
        amount: 0.01,
        buySymbol: "ETH",
        createdAt: "2020-11-16T23:36:31.682Z",
        fee: 0,
        filledAmount: 0,
        inQueue: false,
        liquidity: "binance",
        pair: "ETH/BTC",
        sellSymbol: "BTC",
        show: false,
        status: "open",
        type: "limit",
        unitPrice: 0.03,
        updatedAt: "2020-11-16T23:36:31.682Z",
        userId: "5f93a7f2e719af78c0d6ce18",
        __v: 0,
        _id: "5fb30cffc840a53cffdff24b"
    }
]