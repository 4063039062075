import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import * as serviceWorker from './serviceWorker';

import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';

import App from './app';
import rootSaga from './store/sagas';
import rootReducer from './store/reducers';
import 'font-awesome/css/font-awesome.min.css';

serviceWorker.unregister();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));


sagaMiddleware.run(rootSaga);
render(
  <Provider store={store}>
    <AppContainer>
        <App />
    </AppContainer>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) module.hot.accept(App);