import io from 'socket.io-client';

/* -- set app title --*/
const AppTitle = 'Silver horizon Exchange';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs backend --*/
const testing = 'http://3.21.55.164:4000';
const development = 'https://deserver.silvercyper.com';
const production = 'https://pkeepserver.theellipsis.exchange';

/* -- set API URLs Frontend --*/
const testingUser = 'http://3.21.55.164:3000';
const productionUser = 'https://pkeep.theellipsis.exchange';
const developmentUser = 'https://development.crowdpoint.tech';

let SocketUrl, userUrl;
switch (AppMode[0]) {
  case 'development':
    SocketUrl = development;
    userUrl = developmentUser;
    break;
  case 'production':
    SocketUrl = production;
    userUrl = productionUser;
    break;
  case 'testing':
    SocketUrl = testing;
    userUrl = testingUser;
    break;
  default:
    userUrl = 'http://localhost:3000';
    SocketUrl = 'http://192.168.18.4:4000';
}

let socket = io(SocketUrl);
let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, userUrl, SocketUrl, socket, AppMode };
