import axios from "axios";
import moment from "moment";
import { Fragment } from "react";
import { connect } from "react-redux";
import snsWebSdk from '@sumsub/websdk';
import { Link } from "react-router-dom";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "./index.css";
import Navbar from "../../components/Navbar";
import AppLoader from "../../components/AppLoader";
import NumberFormat from '../../components/NumberFormat';
import {
  myPage,
  getKYCData,
  verifyKYC,
  changePassword,
  disableSMS,
  disableGoogle,
  toggleModalPhishing,
  setAntiPhishing,
  toggleKYCModal,
  getAPIKeys,
} from "../../store/actions/Auth";
import { getWalletBalance } from '../../store/actions/Wallet';

class MyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKYCModal: false,
      isPasswordModal: false,

      formData: {
        oldPassword: "",
        newPassword: "",
      },
      antiPhishing: "",
      applicantID: "",

      kycStatus: {
        pending: {
          tag: 'Application Pending',
          bullet: 'orange-bullet',
          message: 'Your application is under Process',
        },
        queued: {
          tag: 'Application in Que',
          bullet: 'red-bullet',
          message: 'The checks have been started for your application',
        },
        Approved: {
          tag: 'Identity Verified',
          bullet: 'green-bullet',
          message: 'You are sucessfully verified',
        },
        Rejected: {
          tag: 'Application Rejected',
          bullet: 'red-bullet',
          message: 'Your application has been rejected. Please contact customer support',
        },
        onHold: {
          tag: 'Application In Hold',
          bullet: 'orange-bullet',
          message: 'Requires investigation - please wait for a final decision',
        }
      },
    };
    props.myPage();
    props.getKYCData();
    props.getWalletBalance();
    window.scroll(0, 0);
  };

  // componentDidMount() {
  //   if (window.innerWidth <= 767)
  //     this.setState({ offsetAPI: false })

  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth <= 767)
  //       this.setState({ offsetAPI: false })
  //   });
  // }
  props
  componentWillReceiveProps({ isPasswordModal, isModalAntiPhishing }) {
    if (!isModalAntiPhishing) this.setState({ antiPhishing: '' })
    if (!isPasswordModal) {
      let { formData } = this.state;
      formData['newPassword'] = '';
      formData['oldPassword'] = '';
      this.setState({ formData });
    }
  }

  togglePasswordModal = () => this.setState({ isPasswordModal: !this.state.isPasswordModal });

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  changePassword = () => {
    let { formData } = this.state;
    this.props.changePassword(formData);
    this.setState({ isPasswordModal: false });
  };

  keyPressed = (e) => {
    if (e.key === "Enter") {
      this.changePassword();
    }
  };

  handleDisableSMS = () => this.props.disableSMS();

  handleAntiPhishing = () => {
    let { antiPhishing } = this.state;
    this.props.setAntiPhishing({ antiPhishing });
  };

  handleAddAPI = () => this.props.history.push('/apiKeys');

  render() {
    let { newPassword, oldPassword } = this.state.formData;
    let { isPasswordModal, antiPhishing, kycStatus } = this.state;
    let { overview, lightMode, userDetails, loginHistory, disableGoogle, isModalAntiPhishing, toggleModalPhishing, kycData, toggleKYCModal, isKYCModal, } = this.props;

    let kyc = userDetails['kyc'] || {};
    console.log("**************kyc::", kyc);

    return (
      <>
        <div className={`page-wrapper-inner ${lightMode ? "light" : "dark"}`}>
          <Navbar {...this.props} />
          <section className="profile-detail-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="profile-tabs-content">
                    <div className="funds-areas">
                      {userDetails['account'] ? <>
                        <h3>
                          {/* <span style={{ textTransform: 'capitalize' }}> {userDetails['account']} </span> */}
                        Profile
                      </h3>
                        <a href="#" className="newEmail">
                          {userDetails["email"]}
                        </a>
                        <a
                          className="change-pass"
                          href="#"
                          onClick={this.togglePasswordModal}
                        >
                          {/* <i className="icon">
                          <img
                            src={require("../../static/images/icons/47.png")}
                            alt=""
                          />
                        </i> */}
                          <i className="fa fa-lock icon" aria-hidden="true"></i>
                        Change Password
                      </a>
                      </>
                        : <AppLoader />
                      }
                    </div>

                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="profile-tabs-content">
                    {!userDetails['account'] ? <AppLoader /> :
                      <ul className="funds-area">
                        <li>
                          <h3>Total Funds</h3>
                          <span><NumberFormat value={overview['btcTotal'] || 0} coin={'BTC'} /> {'  '}BTC</span>
                          <span><NumberFormat value={overview['usdtTotal'] || 0} coin={'USDT'} /> {'  '} USD</span>
                        </li>
                        {/* <li>
                        <h3>Group Name</h3>
                        <p>No Group</p>
                      </li> */}
                      </ul>}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="profile-tabs-content">
                    {!userDetails['account'] ? <AppLoader /> :
                      <ul className="funds-area">
                        <li>
                          <h3>Last Login IP</h3>
                          <span>{userDetails["lastIp"]}</span>
                        </li>
                      </ul>}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="profile-tabs-content">
                    {!userDetails['account'] ? <AppLoader /> :
                      <ul className="funds-area">
                        <li>
                          <h3>Last Login Time</h3>
                          <span>
                            {moment(userDetails["lastLogin"]).format("dddd, Do MMMM, h:mm:ss a")}
                          </span>
                        </li>
                      </ul>}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="services-box">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block ">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/48.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>Google Authentication</h3>
                      <p className="p1">This is used to add security by changing password in every 30sec on google authentication application.</p>
                      <div className="mt-3">
                        {userDetails["twoFaEnabled"] ? (
                          <a className="disable-btn" href="#" onClick={disableGoogle}>
                            Disable
                          </a>
                        ) : (
                            <Link className="enable-btn" to="/googleAuthentication">Enable</Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/49.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>SMS Authentication</h3>
                      <p className="p2" >Used to attach phone number verification with your account. It will send code on your phone at each login.</p>
                      <div className="mt-3">
                        {userDetails["smsEnabled"] ? (
                          <a onClick={this.handleDisableSMS} href="#">
                            Disable
                          </a>
                        ) : (
                            <Link className="enable-btn" to="/smsAuthentication">Enable</Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/50.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>Anti-Phishing Phrase</h3>
                      <p className="p3" >This is used to attach a unique code with your account and it will send this code in each email for verification.</p>
                      <div className="mt-3">
                        <Link onClick={toggleModalPhishing}>Change</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/54.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>KYC Verification</h3>
                      {kyc['reviewStatus']
                        ? <Fragment>
                          {kyc['reviewStatus'] == 'INCOMPLETE' || kyc['reviewStatus'] == 'RETURNED' || kyc['reviewStatus'] == 'CANCELED'
                            ? <div className='row kyc-status'>
                              <div className='p3'><p>{kycStatus[kyc['reviewStatus']]['message']}</p></div>
                              <div className='col-12 mt-2 mb-3 d-flex justify-content-center'>
                                <i className={'fas fa-circle bullet mr-3 ' + kycStatus[kyc['reviewStatus']]['bullet']}></i>
                                <p className="status-text">{kycStatus[kyc['reviewStatus']]['tag']}</p>
                              </div>
                              <div className="col-12"><Link className="enable-btn" to='/verifyKYC'>Verify KYC</Link></div>
                            </div>
                            : kyc['reviewStatus'] == 'PENDING' || kyc['reviewStatus'] == 'APPROVED' || kyc['reviewStatus'] == 'VERIFIED' || kyc['reviewStatus'] == 'REJECTED'
                              ? <div className='row kyc-status'>
                                <div className='p3'><p>{kycStatus[kyc['reviewStatus']]['message']}</p></div>
                                <div className='col-12 pt-4 d-flex justify-content-center'>
                                  <i className={'fas fa-circle bullet mr-3 ' + kycStatus[kyc['reviewStatus']]['bullet']}></i><br />
                                  <p className="status-text-two">{kycStatus[kyc['reviewStatus']]['tag']}</p>
                                </div>
                              </div>
                              : null
                          }
                        </Fragment>
                        : <div className='kyc-loader mt-2'><AppLoader /></div>
                      }


                      <p className="p3" >Here you can verify your identity. This is required in order to trade on United World Exchange.</p>
                      {kyc['reviewStatus']
                        ? <Fragment>
                          {(kyc['reviewStatus'] === 'unVerified' || kyc['reviewStatus'] === 'init')
                            ? <div className='mt-3'><Link className="enable-btn" to='/verifyKYC'>Verify KYC</Link></div>
                            : <div className='row mt-2 kyc-status'>
                              <i className={'fas fa-circle bullet mr-3 ' + kycStatus[kyc['reviewStatus']]['bullet']}></i>
                              <p className={kyc['reviewStatus']}>{kycStatus[kyc['reviewStatus']]['tag']}</p>
                              <Tooltip title={kycStatus[kyc['reviewStatus']]['message']} arrow placement="top-start">
                                <Button className='tool-tip-btn'><i class="fas fa-info-circle"></i></Button>
                              </Tooltip>
                            </div>
                          }
                        </Fragment>
                        : <div className='kyc-loader mt-2'><AppLoader /></div>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/50.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>Referral System</h3>
                      <p className="p3" >You can earn thourgh sharing your referral code to your friend and family. When they will trade you will get commission</p>
                      <div className="mt-3">
                        <Link className="enable-btn" to="/referral">Details</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="services-block">
                    <div className="icon-box">
                      <img
                        src={require("../../static/images/icons/50.png")}
                        alt=""
                      />
                    </div>
                    <div className="text-column">
                      <h3>Address Whitelisting</h3>
                      <p className="p3" >You can whitelist your preffered addresses. If whitelist is activated then you can only withdraw to whitelisted addresses</p>
                      <div className="mt-3">
                        <Link className="enable-btn" to="/whiteListing">Details</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='offset-md-3 col-lg-6 col-md-6 col-sm-12'>
                  <div className="services-block">
                    <div className="icon-box">
                      <img src={require("../../static/images/icons/51.png")} alt="" />
                    </div>
                    <div className="text-column">
                      <h3>API</h3>
                      <p>Create API and get access to markets and trading services on Keep Exchange. View API documentation.</p>
                      <button className="api-btn" onClick={this.handleAddAPI}>Add</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="activaty-sec">
            <div className="container-fuild">
              <div className="row">
                <div className="col-12">
                  <div class="activaty-inner">
                    <div className="activaty-active">
                      <a href="#" className="active">
                        Activity
                      </a>
                    </div>
                    <div class="balance-table">
                      <div class="table-responsive">
                        {loginHistory.length > 0 ? (
                          <table class="table table-clr table-striped">
                            <thead>
                              <tr>
                                <th scope="col">
                                  Time <span class="icn-box"></span>
                                </th>
                                {/* <i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i> */}
                                <th scope="col">
                                  IP Address <span class="icn-box"></span>
                                </th>
                                {/* <i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i> */}
                                <th scope="col">Location</th>
                                <th scope="col">Device</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loginHistory.map((data) => {
                                return (
                                  <tr>
                                    <td>
                                      {moment(data["accessTime"]).format("dddd, Do MMMM, h:mm:ss a")}
                                    </td>
                                    <td>{data["ipAddress"]}</td>
                                    <td>
                                      {data["city"]}, {data["countryName"]}
                                    </td>
                                    <td>{data["userAgent"]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                            <AppLoader />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* ---------------CHANGE PASSWORD MODAL--------------- */}

        <Modal isOpen={isPasswordModal} toggle={this.togglePasswordModal} className={`${lightMode ? "light-modal" : "dark-modal"} password-modal`}>
          <ModalHeader toggle={this.togglePasswordModal}>
            <a href="#">
              <img
                className="modal-logo"
                src={require("../../static/images/icons/modal-logo.png")}
                alt="modal-logo"
              />
            </a>
          </ModalHeader>
          <ModalBody>
            <p className="password-title">Change Password</p>
            <hr />
            <ValidatorForm
              className="validator-form"
              onSubmit={this.changePassword}
            >
              <div className="col-10">
                <TextValidator
                  fullWidth
                  type="text"
                  name="oldPassword"
                  value={oldPassword}
                  id="standard-full-width"
                  className="password-input-field"
                  label={<label>Old Password</label>}
                  onKeyPress={this.keyPressed}
                  onChange={this.handleChange}
                  validators={["required"]}
                  errorMessages={["Old Password cannot be empty"]}
                />
              </div>
              <div className="col-10">
                <TextValidator
                  fullWidth
                  type="text"
                  name="newPassword"
                  value={newPassword}
                  label={<label>New Password</label>}
                  className="password-input-field mt-3"
                  onChange={this.handleChange}
                  onKeyPress={this.keyPressed}
                  validators={["required"]}
                  errorMessages={["New Password cannot be empty"]}
                />
              </div>

              <div className="password-btn-div">
                <Button
                  onClick={this.changePassword}
                  variant="contained"
                  className="text-white password-btn"
                >
                  CONFIRM
                </Button>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

        {/* ---------------ANTI PHISHING MODAL--------------- */}
        <Modal isOpen={isModalAntiPhishing} toggle={toggleModalPhishing} className={`${lightMode ? "light-modal" : "dark-modal"} anti-phishing-modal `}>
          <ModalHeader toggle={toggleModalPhishing}>
            <a href="#">
              <img
                className="modal-logo"
                src={require("../../static/images/icons/modal-logo.png")}
                alt="modal-logo"
              />
            </a>
          </ModalHeader>
          <ModalBody>
            <p className="anti-phishing-title">ANTI-PHISHING</p>
            <hr />
            <ValidatorForm
              className="validator-form"
              onSubmit={this.handleAntiPhishing}
            >
              {userDetails["antiPhishing"] && (
                <div className="col-12">
                  <TextValidator
                    fullWidth
                    disabled={true}
                    value={userDetails["antiPhishing"]}
                    variant="outlined"
                    id="standard-full-width"
                    label="Old Anti-Phishing"
                    className="anti-phishing-input-field old-anti-phishing mt-4"
                  />
                </div>
              )}
              <div className="col-12">
                <TextValidator
                  fullWidth
                  name="antiPhishing"
                  value={antiPhishing}
                  variant="outlined"
                  id="standard-full-width"
                  className="anti-phishing-input-field mt-4"
                  label="New Anti-Phishing"
                  onChange={(e) =>
                    this.setState({ antiPhishing: e.target.value })
                  }
                />
              </div>

              <div className="anti-phishing-btn-div">
                <Button
                  onClick={this.handleAntiPhishing}
                  variant="contained"
                  className="text-white anti-phishing-btn"
                >
                  SUBMIT
                </Button>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  myPage,
  getKYCData,
  verifyKYC,
  changePassword,
  disableSMS,
  disableGoogle,
  toggleModalPhishing,
  setAntiPhishing,
  getWalletBalance,
  toggleKYCModal,
  getAPIKeys,
};

const mapStateToProps = ({ Auth, Wallet }) => {
  let { overview } = Wallet;
  let { kycData, lightMode, userDetails, loginHistory, isModalAntiPhishing, isKYCModal } = Auth;
  return { kycData, lightMode, userDetails, loginHistory, isModalAntiPhishing, overview, isKYCModal };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPage);