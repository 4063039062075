import { connect } from 'react-redux';
import EventBus from "eventing-bus";
import { Link } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import React, { Component, Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './index.css';
import Navbar from '../../components/Navbar';
import { getWalletBalance } from '../../store/actions/Wallet';
import { myPage, toggleWhitelist, getWhitelistedAddress, addWhitelistAddress, deleteAddress, deleteMultiAddresses } from '../../store/actions/Auth';



class Whitelisting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWhitelist: '',
            coinImg: '',
            coinName: '',
            symbol: 'BTC',
            label: '',
            address: '',
            search: '',
            add: false,
            multiDelete: [],
            coinsList: props.wallets,
            selectedCoin: {},
            whitelistedAddressArr: []
        };

        props.myPage();
        props.getWalletBalance();
        props.getWhitelistedAddress();

    };

    componentWillReceiveProps({ wallets, whitelistedAddress, userDetails }) {
        let { isWhitelistAdded } = this.props;
        if (isWhitelistAdded) this.setState({ label: '', address: '' });
        this.setState({ whitelistedAddressArr: whitelistedAddress, isWhitelist: userDetails['whitelisting'] })
        this.setState({ coinsList: wallets, selectedCoin: wallets.filter(({ symbol }) => symbol === this.state.symbol)[0] },
            () => {
                let { selectedCoin } = this.state;
                if (selectedCoin) this.setState({ coinName: selectedCoin['name'], coinImg: selectedCoin['icon'] })
            });
    };

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value },
        () => {
            let { search } = this.state;
            let { whitelistedAddress } = this.props;
            if (search !== null) this.setState({ whitelistedAddressArr: whitelistedAddress.filter(({ label }) => label.toLowerCase().indexOf(search) >= 0) })
        });

    toggleWhitelist = () => this.props.toggleWhitelist();

    handleCoin = (coin) => this.setState({ coinName: coin['name'], coinImg: coin['icon'], symbol: coin['symbol'] })

    submitWhitelistAddress = () => {
        let { label, address, symbol } = this.state;
        let data = { label, address, symbol };
        this.props.addWhitelistAddress(data);
    };

    handleDelete = (id) => this.props.deleteAddress(id);

    selectedAddress = (e, id) => {
        let { multiDelete } = this.state;
        if (e.target.checked) {
            multiDelete.push(id);
            this.setState({ multiDelete })
        }
        else {
            multiDelete.splice(multiDelete.indexOf(id), 1)
            this.setState({ multiDelete })
        }
    };

    handleMultiDelete = () => {
        let { multiDelete } = this.state;
        if (multiDelete.length > 0) this.props.deleteMultiAddresses({ id: multiDelete });
        else EventBus.publish("error", "No Address Selected");
    };

    render() {
        let { lightMode, userDetails } = this.props;
        let { isWhitelist, label, address, search, coinsList, coinImg, coinName, whitelistedAddressArr } = this.state;
        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />
                <section className="whitelisting">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="whitelisting-form ">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-11 title d-flex justify-content-center'>
                                                <h2>Address Withdrawal Management</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="whitelisting-address-manage">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-6 left-heading-white ">
                                                <h4>Add Withdrawal Address </h4>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-12"></div>
                                                    <div className="col-lg-10 col-md-10 col-12">
                                                        <div className="right-list-white float-right">
                                                            <FormControlLabel
                                                                control={<Switch checked={isWhitelist} onChange={this.toggleWhitelist} />}
                                                            />
                                                            <h5> Whitelist {isWhitelist ? 'On' : 'Off'}</h5>
                                                            <div className="second-white ml-3"><img src={require("../../static/images/icons/question-white.png")} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row coin-row-second">
                                            <div className="col-lg-2">
                                                <div className="dropdown-bitcoin main-header">
                                                    <h4>Coin</h4>
                                                    <div class="dropdown">
                                                        <button class="btn  dropdown-toggle button_drop" type="button" data-toggle="dropdown">
                                                            <span><img src={coinImg} alt="" /></span>
                                                            {coinName}
                                                            <span><i class="fa fa-angle-down down_caret" aria-hidden="true"></i></span>
                                                        </button>
                                                        <ul class="dropdown-menu menu_width">
                                                            {coinsList.map(coin => <li><button className="change-style" onClick={() => this.handleCoin(coin)}>{coin['name']} {coin['symbol']}</button></li>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="second-drop">
                                                    <h4>Label</h4>
                                                    <div className="textbox-white">
                                                        <div className="textbox">
                                                            <input onChange={this.handleChange} value={label} name="label" type="text" placeholder="Label"></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="second-drop">
                                                    <h4>Address</h4>
                                                    <div className="textbox-white">
                                                        <div className="textbox">
                                                            <input onChange={this.handleChange} value={address} name="address" type="text" placeholder="Wallet Address"></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div>
                                                    <button onClick={this.submitWhitelistAddress} className="btn-style-threref">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="whitelisting-form whitelist-eror mt-5">
                                    <div className="whitelisting-address-manage whitelist-erorr ">
                                        <div className="row">
                                            <div className="col-md-6 left-heading-white ">
                                                <h4>All Whitelisted Addresses </h4>
                                                    <div className="thead-white">
                                                    <span><a onClick={this.handleMultiDelete} href="#">Delete Multiples</a> <i class="fa fa-trash" aria-hidden="true"></i></span>
                                                    </div>
                                                  
                                              
                                            </div>
                                            <div className="col-md-4 form-search whitelist-erorrr buttonInnnss whitelist-erorrr row ml-auto">
                                              
                                                <div>
                                                    <input onChange={this.handleChange} value={search} name="search" type="text" className="input-button" placeholder="Search Label" id="enter" />
                                                    <button id="clear">
                                                        <i class="fas fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-2">
                                                <thead className="thead-white float-right">
                                                    <span><a onClick={this.handleMultiDelete} href="#">Delete Multiples</a></span>
                                                </thead>
                                            </div> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="overflow-x:auto">
                                                    <div class="table-responsive pt-3 whitelisting-table">
                                                        <table class="table table-clr table-striped text-center ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Select </th>
                                                                    <th scope="col">Coin </th>
                                                                    <th scope="col">Label </th>
                                                                    <th scope="col">Addresses </th>
                                                                    <th scope="col">Memo/Tag </th>
                                                                    <th scope="col">Delete </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {whitelistedAddressArr.length > 0
                                                                    ? <Fragment>
                                                                        {whitelistedAddressArr.map(data => {
                                                                            return (
                                                                                <tr>
                                                                                    <td><input onChange={(e) => this.selectedAddress(e, data['_id'])} type="checkbox" className="checkcolor" /></td>
                                                                                    <td>{data['symbol']}</td>
                                                                                    <td>{data['label']}</td>
                                                                                    <td>{data['address']}</td>
                                                                                    <td>--</td>
                                                                                    <td>
                                                                                        <button className='delete-btn' onClick={() => this.handleDelete(data['_id'])}>
                                                                                            <img src={require("../../static/images/icons/cancel.png")} alt="" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </Fragment>
                                                                    : <tr>
                                                                        <td colSpan={7}>
                                                                            <p className='my-5'>No Whitelisted Address</p>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = { myPage, toggleWhitelist, getWhitelistedAddress, addWhitelistAddress, getWalletBalance, deleteAddress, deleteMultiAddresses };
const mapStateToProps = ({ Auth, Wallet }) => {
    let { lightMode, userDetails, whitelistedAddress, isWhitelistAdded } = Auth;
    let { wallets } = Wallet;
    return { lightMode, userDetails, whitelistedAddress, wallets, isWhitelistAdded };
};

export default connect(mapStateToProps, mapDispatchToProps)(Whitelisting);