import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import MainNavbar from '../../components/MainNavbar';
import NumberFormat from '../../components/NumberFormat';
import { verifyEmail, getCryptoRates } from '../../store/actions/Auth';
import { verifyWithdrawal } from '../../store/actions/Wallet';

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rates: '',
      total: '0',
      singleRate: '-',
      to: 'USDT',
      amount: '',
      from: 'BTC',
      coins: ['BTC', 'ETH', 'DASH', 'LTC', 'BNB', 'XRP', 'BCHABC', 'DOGE', 'IOTA', 'EOS']
    };
    props.getCryptoRates();
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.match('verify_withdrawal')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyWithdrawal({ data, history: this.props.history });
    }

    if (location.pathname.match('verifyEmail')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyEmail({ data, history: this.props.history });
      this.props.history.push('/');
    }
  };

  componentWillReceiveProps({ cryptoRates }) {
    this.setState({ rates: cryptoRates },
      () => this.setSingleCryptoRate());
  }

  setSingleCryptoRate = () => {
    let { to, from, rates } = this.state;
    let coin = `${from}${to}`;
    this.setState({ singleRate: rates[coin] * 1 });
  };

  setCryptoRate = () => {
    let { to, from, rates, amount } = this.state;
    let coin = `${from}${to}`;
    this.setState({ total: rates[coin] * amount });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value },
    () => this.setCryptoRate());

  handleCoin = (coin) => this.setState({ from: coin },
    () => {
      this.setCryptoRate();
      this.setSingleCryptoRate();
    });


  render() {
    let { coins, from, amount, total, singleRate } = this.state;
    let { auth, landingBannerData, landingTableData } = this.props;
    return (
      <div style={{ background: 'white' }}>
        <MainNavbar {...this.props} />
        <section className="banner-sections style-twos" style={{ backgroundImage: `url(${require("../../static/images/new-landing/banner-background.png")})` }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="content-boxs">
                  <h1>Trade with the lowest<br></br> <span>trading fees in the world.</span></h1>
                  <div className="texts">We believe in giving value to our customers by compensating with lowest fees in the market.<br></br> Your success is our success. </div>
                  <div className="link-boxs">
                    <Link to={auth ? '/trading' : '/signup' } className="theme-btn btn-style-one"> Trade Now </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="image-columns">
                  <figure><img src={require("../../static/images/new-landing/Silver-Horizone-New-website.png")} alt="" /></figure>
                </div>
              </div>
            </div>
          </div>
          
        </section>

        {/* <section className="background-image">
          <div className="container">
            <div className="background-imag">
              <img src={require("../../static/images/new-landing/banner-logo.png")} alt="" />
            </div>
          </div>
        </section> */}

        <section className="silver-company-graph landing-graph" style={{ backgroundImage: `url(${require("../../static/images/new-landing/silver-company-background.png")})` }}>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>United World Exchange</h2>
                </div>
                <div className="image-main text-center">
                  <div id="currencyCalculator" className="form-graph-silver-crypto">
                    <div className="row you-send">
                      <div className="col-6">
                        <input value={amount} name="amount" placeholder="Enter Amount" onChange={this.handleChange} />
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div class="dropdown">
                            <button class="btn  dropdown-toggle" type="button" data-toggle="dropdown">
                              <span><img src={require(`../../static/images/new-landing/${from}.png`)} alt="" /></span>
                              {from}
                            </button>
                            <ul class="dropdown-menu">
                              {coins.map(coin => <li className="py-2 pl-2"><button onClick={() => this.handleCoin(coin)}>{<img src={require(`../../static/images/new-landing/${coin}.png`)} alt="" />} {coin}</button></li>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="expected-rate">1 {from} = {singleRate} USDT Expedted Rate</p>
                    <div className="row you-receive">
                      <div className="col-6"><p>{total}</p></div>
                      <div className="col-6">
                        <div className="row justify-content-center">
                          <img src={require("../../static/images/new-landing/USDT.png")} alt="" />
                          <p>USDT</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={require("../../static/images/new-landing/silver-company-graph.png")} className="graph-image" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="silver-blockchain" id="about">
          <img src={require("../../static/images/new-landing/silver-block-side-back.png")} className="silver-block-side" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 border-t">
                <div className="texts-left">
                  <h4>Trade fees as low as .1% per transaction</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="texts-right">
                  <p>The United World Organization Exchange where enthusiastic Crypto buyers meet the sellers</p>
                </div>
              </div>
            </div>

            <div className="row top-padding">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text">
                  <div className="image-inner">
                    <img src={require("../../static/images/new-landing/performance.png")} alt="" />
                  </div>
                  <h2>Performance</h2>
                  <p>High performance with seamless and fast transactions.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text images-texts">
                  <div className="image-inner ">
                    <img src={require("../../static/images/new-landing/protection.png")} alt="" />
                  </div>
                  <h2>Protection</h2>
                  <p>We provide security to our customers whether its spot futures or P2P trading you can always claim or appeal.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text images-texts">
                  <div className="image-inner image-inners">
                    <img src={require("../../static/images/new-landing/privacy.png")} alt="" />
                  </div>
                  <h2>Privacy</h2>
                  <p>We believe in our customers' privacy as a blockchain exchange and keep and all data safe and secure behind virtual walls.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="trade-on" style={{ backgroundImage: `url(${require("../../static/images/new-landing/trade-on.png")})` }}>
          <img src={require("../../static/images/new-landing/trade-on-men.png")} className="men-laptop" alt="" />
          <div className="container">
            <div className="titles-headings text-center">
              <h4>Trade On the Cryptocurrency Derivatives Exchange with the Best <br></br>Perpetual Contracts</h4>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point<br></br> of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',<br></br> making it look like readable English. Many desktop publishing</p>
            </div>
            <div className="trade-on-image">
              <img src={require("../../static/images/new-landing/222.png")} alt="" />
            </div>
          </div>
        </section>

        <section className="table-sec landing-table">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2>United World Exchange</h2>
                </div>
              </div>
            </div>
            <div className="table-responsive-lg">
              <table className="table table-hover table-striped unique">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col ml-2">Coin Name</th>
                    <th scope="col"><span className="">Price</span></th>
                    <th scope="col"><span className="">24-hrs Volume</span> </th>
                    <th scope="col"><span className="">24-hrs Change</span> </th>
                  </tr>
                </thead>
                <tbody className="tbody-dark">
                  {Object.values(landingTableData).map(data => {
                    return (
                      <tr>
                        <th scope="row" className="first-ch"><img className='landing-table-img' src={require(`../../static/images/icons/${data['symbol']}.png`)} alt="" />{data['name']}</th>
                        <td className='text-dark'>$<NumberFormat value={data['close']} decimals={2} /></td>
                        <td className='text-dark'><NumberFormat value={data['volume']} decimals={2} /> {data['symbol']}</td>
                        <td className='text-dark'><NumberFormat value={data['change']} decimals={2} /> %</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="countries" >
          <div className="container">
            <div className="titles-heading text-center">
              <h5>Countries</h5>
              <h2>100,000 Registered Users and Counting</h2>
              <p>Change Your Virtual Location at any time. Switch between countries in second.</p>
            </div>
            <div className="row">
              <div className="col-lg-10 col-md-10 col-12 offset-lg-1 offset-md-1 0ffset-0">
                <div className="image-countries">
                  <img src={require("../../static/images/new-landing/countries-register-main.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="keep-in-touch">
          <img src={require("../../static/images/new-landing/downarrorw.png")} className="arrowdown" alt="" />
          <img src={require("../../static/images/new-landing/uparrow.png")} className="arrowup" alt="" />
          <div className="container">
            <div className="titles-heading text-center">
              <h2>Stay in touch.</h2>
            </div>
            <div className="row ">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text text-center">
                  <div className="image-inners image-one">
                    <img src={require("../../static/images/new-landing/keep-in-touch3.png")} alt="" />
                  </div>
                  <h2>24/7 Support</h2>
                  <p>We will have a dedicated team to give you 24 hours around the clock support. We believe in giving our customers nothing but the best.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text images-texts text-center community-pass">
                  <div className="image-inners image-two">
                    <img src={require("../../static/images/new-landing/keep-in-touch.png")} alt="" />
                  </div>
                  <h2>Community</h2>
                  <p>We have a community on different platforms like twitter, Discord and Telegram. Interact with fellow believers and also get the latest updates.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="image-text images-texts text-center">
                  <div className="image-innerss image-inners image-three">
                    <img src={require("../../static/images/new-landing/keep-in-touch2.png")} alt="" />
                  </div>
                  <h2>Careers</h2>
                  <p>If Interested in working in an charity organisation feel free to get in touch through Email at careers@unitedworldorganisation.com</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="main-footer" style={{ backgroundImage: `url(${require("../../static/images/new-landing/footer-back.png")})` }}>
          <div className="container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget about-widget">
                    <div className="footer-logo"><a href="index.html"><img src={require("../../static/images/06-01.png")} alt="" /></a></div>

                  </div>
                </div>
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h5 className="widget-title">QUICK LINKS</h5>
                    <div className="widget-content">
                      <ul className="list">
                        <li><Link to="#">Home</Link></li>
                        <li><Link smooth to={auth ? '/trading' : '#' }>Trade</Link></li>
                        <li><Link to="#">Career </Link></li>
                        <li><Link to="#">Blog</Link></li>
                        <li><HashLink smooth to='/#currencyCalculator'>Currency Calculator</HashLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4 className="widget-title">PRODUCTS</h4>
                    <div className="widget-content">
                      <ul className="list">
                        <li><Link to="#">Contact Us</Link></li>
                        <li><Link to="#">FAQ </Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-column col-lg-3 col-md-2 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h2 className="widget-title">CONTACT US</h2>
                    <div className="widget-content">
                      <ul className="list-address">
                        <li>info@unitedworldtoken.com<br /></li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="main-div">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="leftside-footer">
                          <p>Copyright © 2021, United World Token</p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="rightside-footer d-flex ">
                          <p className="">Follow On Us:</p>
                          <div className="footer-socials-one-two social-ones text-center">
                            <a href="" className="social-one social-footer-discord"><img src={require("../../static/images/new-landing/discord1.png")} alt="" /></a>
                          </div>
                          <div className="footer-socialss social-twos">
                            <a href="" className="social-two"> <span className="pr-4"><i className="fa fa-twitter" aria-hidden="true"></i></span></a>
                          </div>
                          <div className="footer-socialss social-threes">
                            <a href="" className="social-three"><span className="pr-4"><i class="fa fa-telegram" aria-hidden="true"></i></span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  let { auth, landingBannerData, landingTableData, cryptoRates } = Auth;
  return { auth, landingBannerData, landingTableData, cryptoRates };
};
const mapDispatchToProps = { getCryptoRates, verifyWithdrawal, verifyEmail };

export default connect(mapStateToProps, mapDispatchToProps)(Landing);