import moment from 'moment';
import { Button } from 'reactstrap';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AppMode } from '../../../store/config';
import AppLoader from '../../../components/AppLoader';
import NumberFormat from '../../../components/NumberFormat';
import { getCoinWallet, getWithdrawHistory, withdrawalRequest, setCoin } from '../../../store/actions/Wallet';

class Crypto extends Component {

  constructor(props) {
    super(props);
    this.state = {
      withdrawal: {
        address: '',
        amount: '',
      },
      walletList: [],
      whitelistAdddress: [],
      type: 'Withdraw',
      selectedWallet: props.selectedWallet,
      condition: {
        0: 'Pending',
        1: 'Completed',
        2: 'Failed',
        3: 'Cancelled',
        4: 'Processing',
      },
    };
    props.getCoinWallet(props.selectedWallet);
    props.getWithdrawHistory({ type: this.state.type, symbol: props.selectedWallet });
  }

  componentWillReceiveProps({ selectedWallet, wallets }) {
    if (selectedWallet !== this.state.selectedWallet) {
      this.setState({ selectedWallet });
      this.props.getCoinWallet(selectedWallet);
      this.props.getWithdrawHistory({ type: this.state.type, symbol: selectedWallet })
    }

    this.setState({ walletList: wallets.filter(({ symbol }) => symbol !== selectedWallet) });

    let AddressesArr = wallets.filter(({ symbol }) => symbol == selectedWallet)[0];
    if (AddressesArr) this.setState({ whitelistAdddress: AddressesArr['whitelisted'] },
      () => this.setState({ withdrawal: { address: '', amount: '' } })
    );
  }

  handleChange = (e) => {
    const { withdrawal } = this.state;
    withdrawal[e.target.name] = e.target.value;
    this.setState({ withdrawal });
  }

  selectedAddress = (e, value) => {
    let { withdrawal } = this.state;
    if (value) {
      withdrawal['address'] = value['address']
      this.setState({ withdrawal });
    }
  }

  submitWithdrawalRequest = () => {
    let { selectedWallet } = this.props;
    let { withdrawal, type } = this.state;
    if (withdrawal['amount'] <= 0) {
      EventBus.publish("error", "Amount can't be Zero")
      return;
    }
    this.props.withdrawalRequest({ symbol: selectedWallet, toAddress: withdrawal['address'], value: withdrawal['amount'], type })
    withdrawal['address'] = '';
    withdrawal['amount'] = '';
    this.setState({ withdrawal });
    // setTimeout(() => {
    //   this.props.getCoinWallet(selectedWallet);
    //   this.props.getWithdrawHistory({ type, symbol: selectedWallet });
    // }, 3000);
  }

  maxAmount = () => {
    let { withdrawal } = this.state;
    let { coinWallet } = this.props;

    withdrawal['amount'] = coinWallet['confirmedBalance'];
    this.setState({ withdrawal });
  }

  render() {
    let { coinWallet, withdrawHistory, coinLoader, withdrawLoader, withdrawHistoryLoader } = this.props;
    let { condition, walletList, withdrawal, selectedWallet, whitelistAdddress } = this.state;
    let coinData = coinWallet['coinId'] || {};
    let userData = coinWallet['userId'] || {};
    return (
      <>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {coinLoader
              ? <div className='pt-5 mt-5'><AppLoader message={`Fetching your wallet details`} /></div> :
              <div className="qr-sec">
                <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                  <div className="card">
                    <div className="card-header" role="tab" id="headingOne1">
                      <div class="btn-group">
                        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="icon-left">
                            <img src={coinData['icon']} className="dropdown-icon" alt="Bitcoin Logo" />
                          </span>
                          {coinData['coinName']} ({coinData['symbol']})
                        </button>
                        <div class="dropdown-menu">
                          {walletList.map(wallet => (
                            <button class="dropdown-item" type="button" onClick={() => this.props.setCoin(wallet['symbol'])}>
                              <span class="icon-left">
                                <img src={wallet['icon']} className='dropdown-icon' alt="Bitcoin Logo" />
                              </span>
                              {wallet['name']} ({wallet['symbol']})
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card-body withdraw-card">
                      <form className="withdraw-balance">
                        <div className="balance-total">
                          <h5><span>Balance</span>
                            {/* <br /> */}
                            {coinWallet['confirmedBalance']
                              ? <Fragment>
                                <NumberFormat value={coinWallet['confirmedBalance']} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                              </Fragment>
                              : <Fragment>
                                <NumberFormat value={0} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                              </Fragment>
                            }
                          </h5>
                          {/* <h5><span>24h Limit</span>
                            <br />
                            {coinData['limits24hr']
                              ? <Fragment>
                                <NumberFormat value={coinWallet['limit24hr']} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                                {' '}/{' '}
                                <NumberFormat value={coinData['limits24hr'][userData['step']]} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                              </Fragment>
                              :
                              <Fragment>
                                <NumberFormat value={0} coin={coinWallet['symbol']} />  {coinWallet['symbol']}
                                {' '}/{' '}
                                <NumberFormat value={0} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                              </Fragment>
                            }
                          </h5> */}
                        </div>
                        <div className="group-form">
                          {whitelistAdddress
                            ?
                            <Fragment>
                              <Autocomplete
                                className="autocomplete-field"
                                options={whitelistAdddress}
                                autoHighlight
                                getOptionLabel={(option) => option.address}
                                onChange={(event, newValue) => this.selectedAddress(event, newValue)}
                                renderOption={(option) => (
                                  <Fragment>
                                    <span style={{ color: 'grey', fontSize: '14px' }}>
                                      Label: {option.label} <br />
                                      Address: <span style={{fontSize: '12px'}}>{option.address}</span>
                                    </span>
                                  </Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={<label>Address</label>}
                                    className='form-input-field'
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                              <span style={{ fontSize: '11px' }}>
                                <span style={{ color: '#ffaa06' }}>Whitelisting is activated</span> you can only withdraw to your whitelisted addresses.
                              </span>
                            </Fragment>

                            : <input name='address' value={withdrawal['address']} type="text" className="control-form" placeholder="Address" onChange={this.handleChange} />
                          }
                        </div>
                        <div className="group-form style-2">
                          {/* <TextField
                                fullWidth
                                className="MyTextField form-input-field control-form"
                                autoComplete='off'
                                label="Amount"
                                name="amount"
                                type="number"
                                value={withdrawal['amount']}
                                margin="dense"
                                onChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['Amount can not be empty']}
                              /> */}
                          <input name='amount' value={withdrawal['amount']} type="number" className="control-form control-form-amount" placeholder="Amount" onChange={this.handleChange} />
                          <span className="max-button" onClick={this.maxAmount}>Max</span>
                          {/* <span className="icon fa fa-angle-up"></span> */}
                          {/* <span className="icon fa fa-angle-down"></span> */}
                        </div>
                        <div className="group-form">
                          <div className="amount-fees">
                            <h3>Withdraw Amount:  <span className="green"> <NumberFormat value={withdrawal['amount'] || '0'} coin={coinData['symbol']} /> {selectedWallet}</span></h3>
                            <h3>Transactoin Fees: <span className="red"> - <NumberFormat value={coinData['withdrawFee']} coin={coinData['symbol']} /> {coinWallet['symbol']}</span></h3>
                          </div>
                        </div>
                        <div className="group-form">
                          <div className="amount">
                            <h3>Total Amount:  <span className=""><NumberFormat value={(withdrawal['amount']) ? withdrawal['amount'] - coinData['withdrawFee'] : '0'} coin={coinData['symbol']} /> {coinWallet['symbol']}</span></h3>
                          </div>
                        </div>
                        <div className="group-form">
                          <button type='button' className="theme-btn btn-style-three" onClick={this.submitWithdrawalRequest} disabled={withdrawLoader}>
                              {!withdrawLoader ? 'Submit' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bottom-note">
                  <h4>Minimum withdrawal : {coinData['minWithdraw']}</h4>
                  <p>
                    Do not withdraw directly to ICO address.<br />
                    After Submit, You have to confirm it through your email.
                  </p>
                </div>
              </div>
            }
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="history-deposit">
              <h5>History</h5>
              <div className="table-responsive-md">
                <table className="table table-clr text-center">
                  <thead>
                    <tr>
                      <th scope="col">Time <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                      <th scope="col">Coin <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                      <th scope="col">Amount <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                      <th scope="col">Status <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                      <th scope="col">Tx ID <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawHistoryLoader ?
                      <tr>
                        <td colSpan={7}>
                          <AppLoader message={"Loading Withdraw history"} />
                        </td>
                      </tr>
                      :
                      <Fragment>
                        {withdrawHistory.map(data => {
                          let explorer = AppMode[0] === 'production' ? data['coinId']['mainExplorer'] : data['coinId']['testExplorer'];
                          return (
                            <tr>
                              <td>{moment(data['createdAt']).format('MMM Do, h:mm a')}</td>
                              <td>{data['symbol']}</td>
                              <td><NumberFormat value={data['value']} coin={data['symbol']} /></td>
                              <td>{condition[data['status']]}</td>
                              <td>{data['transactionHash'] ? <a href={explorer + data['transactionHash']} target='_blank' className="wallet-table-btn">View</a> : '--'}</td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getCoinWallet, getWithdrawHistory, withdrawalRequest, setCoin };

const mapStateToProps = ({ Wallet }) => {
  let { selectedWallet, coinWallet, withdrawHistory, wallets, coinLoader, withdrawLoader, withdrawHistoryLoader } = Wallet;
  return { selectedWallet, coinWallet, withdrawHistory, wallets, coinLoader, withdrawLoader, withdrawHistoryLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crypto);