import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import TextField from '@material-ui/core/TextField';

import './index.css';
import Navbar from '../../components/Navbar';
import { enableGoogleAuth, googleAuthVerify } from '../../store/actions/Auth';

class GoogleAuthentication extends Component {
    state = {
        token_code: ''
    }

    componentDidMount() {
        this.props.enableGoogleAuth();
    }

    submitGoogle = () => {
        let { token_code } = this.state;

        let data = { token_code };
        let { history } = this.props;
        this.props.googleAuthVerify({ data, history });
    }

    render() {
        let { token_code } = this.state;
        let { lightMode, googleAuth } = this.props;
        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />
                <section className="sms-anthtication">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-md-11 col-sm-10 title text-center d-flex justify-content-center'>
                                                <h2>Enable Google Authentication</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="google-auth-step">
                                        <ul>
                                            <li>
                                                <div className="steps-google">
                                                    <div className="top-area">
                                                        <h4>Download App</h4>
                                                        <p>Download and install the Google Authentication application</p>
                                                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img src={require("../../static/images/icons/29.jpg")} alt="" /></a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en" target="_blank"><img src={require("../../static/images/icons/30.jpg")} alt="" /></a>
                                                    </div>
                                                    <div className="bottom-area">
                                                        <HashLink className="theme-btn btn-style-four" smooth to='/googleAuthentication#qrCode'>Next Step</HashLink>
                                                        <p>I have installed the application</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="active">
                                                <div id="qrCode" className="steps-google">
                                                    <div className="top-area">
                                                        <h4>Scan QR Code</h4>
                                                        <div className="qr-box">
                                                            <div className="qr-area-box">
                                                                <img src={googleAuth['qrData']} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-area">
                                                        <HashLink className="theme-btn btn-style-four" smooth to='/googleAuthentication#backupKey'>Next Step</HashLink>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div id="backupKey" className="steps-google">
                                                    <div className="top-area">
                                                        <h4>Backup Key</h4>
                                                        <h5>{googleAuth['secret']}</h5>

                                                        <p>Please save this key on paper. This key will allow <br /> you to recover your Google Authentication in case of phone loss.</p>

                                                        <span className="note"><i className="icon"><img src={require("../../static/images/icons/19.png")} alt="" /></i>
                                                            User needs to open a support ticket to re-set Google Authentication. <br /> It may take at least 7 days to process.
                                                        </span>
                                                    </div>
                                                    <div className="bottom-area">
                                                        <HashLink className="theme-btn btn-style-four" smooth to='/googleAuthentication#googleAuth'>Next Step</HashLink>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div id="googleAuth" className="steps-google">
                                                    <div className="top-area">
                                                        <h4>Enable Google Authentication</h4>
                                                        <div className="row mt-4">
                                                            <div className="group-form col-md-8 col-sm-12">
                                                                <TextField
                                                                    fullWidth
                                                                    name="token_code"
                                                                    type='number'
                                                                    value={token_code}
                                                                    variant="outlined"
                                                                    id='standard-full-width'
                                                                    className='google-input-field'
                                                                    placeholder="Google Authentication Code"
                                                                    onChange={(e) => this.setState({ token_code: e.target.value })}
                                                                />
                                                                {/* <input type="text" className="control-form" placeholder="Google Authentication Code" required /> */}
                                                            </div>
                                                            <div className="group-form col-md-4 col-sm-12">
                                                                <button type="button" className="theme-btn btn-style-four submit-auth-btn" onClick={this.submitGoogle}>Send Code</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = { enableGoogleAuth, googleAuthVerify };
const mapStateToProps = ({ Auth, Trading }) => {
    let { lightMode, googleAuth } = Auth;
    let { } = Trading;
    return { lightMode, googleAuth };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuthentication);