import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import './index.css';
import { logout } from '../../store/actions/Auth';

class MainNavbar extends Component {
  render() {
    let { auth, logout, location } = this.props;
    let { pathname } = location;

    return (
      <>
        <div className="main-header-nav">
          <nav className="navbar navbar-expand-md">
            <div className="container ">
              <Link className="navbar-brand" to="/"><img className="navbar-logo-img" src={require("../../static/images/new-logo-exchanges.jpeg")} alt="silver horizon exchange" /></Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <i className="fa fa-bars " aria-hidden="true"></i>
              </button>
              <div className="collapse navbar-collapse nav-links" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className={`${pathname === '/' && 'current'}`}><Link className='margin-ri' to='/'>Home</Link></li>
                  <li><HashLink className='margin-ri' smooth to='/#about'>About</HashLink></li>
                  {auth ?
                    <>
                      <li><Link className='margin-ri' to='/trading'>Trading</Link></li>
                      <li><Link className='margin-ri' to='/myPage'>My-Page</Link></li>
                      <li><a onClick={logout}>Logout</a></li>
                    </>
                    :
                    <>
                      <li className="button-sign-on"><Link className='margin-ri button-sigin' to='/login'> Sign In </Link></li>

                      <li className="button-sigup-on"><Link className='margin-ri button-signup' to='/signup'> Sign Up </Link></li>
                    </>
                  }
                </ul>

              </div>
            </div>
          </nav>
        </div>

        {/* <header className="main-header header-style-two">
          <div className="header-upper">
            <div className="inner-container">
              <div className="container clearfix">
                <div className="nav-inner">
                  <div className="logo-outer">
                    <div className="logo">
                      <Link to='/'><img className="navbar-logo-img" src={require("../../static/images/logo.png")} alt="silver horizon exchange" /></Link>
                    </div>
                  </div>
                  <div className="nav-outer clearfix">
                    <nav className="main-menu navbar-expand-md navbar-light">
                      <div className="navbar-header">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                        </button>
                      </div>
                      <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                        <ul className="navigation clearfix">
                          <li className={`${pathname === '/' && 'current'}`}><Link className='mr-4' to='/'>Home</Link></li>
                          <li><HashLink className='mr-4' smooth to='/#about'>About</HashLink></li>
                          {auth ?
                            <>
                              <li><Link className='mr-4' to='/trading'>Trading</Link></li>
                              <li><Link className='mr-4' to='/myPage'>My-Page</Link></li>
                              <li><a onClick={logout}>Logout</a></li>
                            </>
                            :
                            <>
                              <li className={`${pathname === ('/login') && 'current'}`}><Link className='mr-1 button-sigin' to='/login'> Sign In </Link></li>
                        
                              <li className={`${pathname === ('/signup') && 'current'}`}><Link className='mr-1 button-signup' to='/signup'> Sign Up </Link></li>
                            </>
                          }
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sticky-header">
            <div className="container-fluid clearfix">
              <div className="nav-inner">
                <div className="logo pull-left">
                  <a href="index.html" className="img-responsive"><img src={require("../../static/images/logo.png")} alt="" title="" /></a>
                </div>
                <div className="right-col pull-right">
                  <nav className="main-menu  navbar-expand-md">
                    <div className="navbar-header">
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent1">
                      <ul className="navigation clearfix">
                        <li className="current"><a href="#">Home</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Portfolio</a></li>
                        <li><a href="#">Pages</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="contact.html">Contact us</a></li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header> */}
      </>
    )
  }
}


const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth };
};
const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);