import axios from "axios";
import EventBus from "eventing-bus";
import { put, all, takeLeading, call } from "redux-saga/effects";
import {
  userData, userAuth, setKYCData, load, myPageData, myPageUserData, toggleAuthModal, toggleResendEmail, 
  googleAuthSettings, toggleModalPhishing, toggleKYCModal, setAPIKeys, APIKeysLoader, setWhitelistedAddress, 
  whitelistAdded, setReferrals, setCryptoRates,
} from '../actions/Auth';


function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/users/auth`, body: payload });
  if (error) {
    if (error["response"]["data"]["body"] == 'resend')
      yield put(toggleResendEmail());
    else EventBus.publish("error", error["response"]["data"]["message"]);
  }
  else if (response) {
    if (response['data']['body']['token']) {
      EventBus.publish("success", response['data']['message']);
      yield put(userData(response['data']['body']));
      setTimeout(() => history.push('/trading'), 300);
    }
    else {
      yield put(toggleAuthModal());
      yield put(userAuth(response['data']['body']));
    }
  }
  yield put(load(false));
}

function* signUpBasic({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/signupBasic`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(load(false));
}

function* signupAdvance({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/users/signupAdvance`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    if (response['data']['body']['token']) {
      EventBus.publish("success", response['data']['message']);
      yield put(userData(response['data']['body']));
      setTimeout(() => history.push('/trading'), 1000);
    }
  }
  yield put(load(false));
};

function* resendEmail({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/resendVerification`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(toggleResendEmail());
}

function* getKYCData() {
  const { error, response } = yield call(getCall, `/users/kycToken`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setKYCData(response['data']['body']));
}

function* verifyEmail({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/users/verifyEmail`, body: { hash: payload } });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    setTimeout(() => history.push('/login'), 300);
  }
}

function* forgotPassword({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/forgotPassword`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(load(false));
}

function* resetPassword({ payload, history }) {
  const { error, response } = yield call(putCall, { path: `/users/setPassword`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    setTimeout(() => history.push('/login'), 1000);
    EventBus.publish("success", response['data']['message']);
  }
  yield put(load(false));
}

function* changePassword({ payload }) {
  const { error, response } = yield call(putCall, { path: '/users/changePassword', body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

function* loginSms({ payload }) {
  const { error, response } = yield call(getCall, `/users/loginSms/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
}

function* verifySmsLogin({ payload, id, history }) {
  yield put(load(true));
  const { error, response } = yield call(postCall, { path: `/users/verifySmsLogin/${id}`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(toggleAuthModal());
    yield put(userData(response['data']['body']));
    setTimeout(() => history.push('/trading'), 1000);
    EventBus.publish("success", response['data']['message']);
  }
  yield put(load(false));
};

function* loginVerifyGoogle({ payload, id, history }) {
  const { error, response } = yield call(postCall, { path: `/users/verify2FAuth/${id}`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(toggleAuthModal());
    yield put(userData(response['data']['body']));
    setTimeout(() => history.push('/trading'), 1000);
    EventBus.publish("success", response['data']['message']);
  }
};

function* enableGoogleAuth() {
  const { error, response } = yield call(getCall, `/users/enable2Factor`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(googleAuthSettings(response['data']['body']))
};

function* googleAuthVerify({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/users/verify2Factor`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    setTimeout(() => history.push('/myPage'), 1000);
    yield put(googleAuthSettings(response['data']['body']));
    EventBus.publish("success", response['data']['message']);
  }
};

function* disableGoogle() {
  const { error, response } = yield call(getCall, `/users/disable2Factor`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(myPageUserData(response['data']['body']));
    EventBus.publish("success", response['data']['message']);
  }
};

function* myPage() {
  const { error, response } = yield call(getCall, `/users/myPage`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(myPageData(response['data']['body']));
};

function* setAntiPhishing({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/setAntiphish`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "MY_PAGE" });
    yield put(toggleModalPhishing());
    EventBus.publish("success", response['data']['message']);
  }
};

function* sendSMS({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/sendSMS`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) EventBus.publish("success", response['data']['message']);
};

function* smsKey({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/enableSMS`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    setTimeout(() => payload.history.push('/myPage'), 1000);
    EventBus.publish("success", response['data']['message']);
  }
};

function* disableSMS() {
  const { error, response } = yield call(getCall, `/users/disableSms`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put(myPageUserData(response['data']['body']));
    EventBus.publish("success", response['data']['message']);
  }
};

function* verifyKYC({ payload }) {
  const { error, response } = yield call(getCall, `/users/updateKyc/${payload['applicantId']}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "MY_PAGE" });
    yield put(toggleKYCModal());
    EventBus.publish("success", response['data']['message']);
  }
};

function* getAPIKeys() {
  const { error, response } = yield call(getCall, `/apiKey`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setAPIKeys(response['data']['body']));
  yield put(APIKeysLoader(false));
};

function* createAPIKey({ payload }) {
  const { error, response } = yield call(postCall, { path: `/apiKey`, body: payload });
  if (error) {
    yield put(APIKeysLoader(false));
    EventBus.publish("error", error['response']['data']['message']);
  } else if (response) {
    yield put({ type: "GET_API_KEYS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteAllAPIKey() {
  const { error, response } = yield call(deleteCall, `/apiKey`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_API_KEYS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteAPIKey({ payload }) {
  const { error, response } = yield call(deleteCall, `/apiKey/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_API_KEYS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* editApiKey({ payload }) {
  const { error, response } = yield call(putCall, { path: `/apiKey/${payload['_id']}`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_API_KEYS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* toggleWhitelist() {
  const { error, response } = yield call(getCall, `/whitelisting/toggle`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'MY_PAGE' });
    EventBus.publish("success", response['data']['message']);
  }
};

function* getWhitelistedAddress() {
  const { error, response } = yield call(getCall, `/whitelisting`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setWhitelistedAddress(response['data']['body']));
};

function* addWhitelistAddress({ payload }) {
  const { error, response } = yield call(postCall, { path: `/whitelisting`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_WHITELISTED_ADDRESS" });
    yield put(whitelistAdded(true));
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteAddress({ payload }) {
  const { error, response } = yield call(putCall, { path: `/whitelisting/${payload}`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_WHITELISTED_ADDRESS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* deleteMultiAddresses({ payload }) {
  const { error, response } = yield call(postCall, { path: `/whitelisting/delete`, body: payload });
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) {
    yield put({ type: "GET_WHITELISTED_ADDRESS" });
    EventBus.publish("success", response['data']['message']);
  }
};

function* getReferrals() {
  const { error, response } = yield call(getCall, `/users/refferals`);
  if (error) EventBus.publish("error", error['response']['data']['message']);
  else if (response) yield put(setReferrals(response['data']['body']));
};

function* getCryptoRates() {
  const { error, response } = yield call(getCall, `/users/cryptoRates`);

  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setCryptoRates(response['data']['body']));
}


function* actionWatcher() {
  yield takeLeading("LOGIN", login);
  yield takeLeading("SIGNUP_BASIC", signUpBasic);
  yield takeLeading("SIGNUP_ADVANCE", signupAdvance);
  yield takeLeading("GET_KYC_DATA", getKYCData);
  yield takeLeading("VERIFY_EMAIL", verifyEmail);
  yield takeLeading("FORGOT_PASSWORD", forgotPassword);
  yield takeLeading("RESET_PASSWORD", resetPassword);
  yield takeLeading('CHANGE_PASSWORD', changePassword);
  yield takeLeading("LOGIN_SMS", loginSms);
  yield takeLeading("VERIFY_SMS_LOGIN", verifySmsLogin);
  yield takeLeading("VERIFY_GOOGLE_LOGIN", loginVerifyGoogle);
  yield takeLeading("ENABLE_GOOGLE_AUTH", enableGoogleAuth);
  yield takeLeading("GOOGLE_AUTH_VERIFY", googleAuthVerify);
  yield takeLeading("DISABLE_GOOGLE", disableGoogle);
  yield takeLeading("SET_ANTI_PHISHING", setAntiPhishing);
  yield takeLeading('MY_PAGE', myPage);
  yield takeLeading('SEND_SMS', sendSMS);
  yield takeLeading('SMS_KEY', smsKey);
  yield takeLeading('DISABLE_SMS', disableSMS);
  yield takeLeading('VERIFY_KYC', verifyKYC);
  yield takeLeading('GET_API_KEYS', getAPIKeys);
  yield takeLeading('CREATE_API_KEY', createAPIKey);
  yield takeLeading('DELETE_API_ALL_KEY', deleteAllAPIKey);
  yield takeLeading('DELETE_API_KEY', deleteAPIKey);
  yield takeLeading('EDIT_API_KEY', editApiKey);
  yield takeLeading('GET_WHITELISTED_ADDRESS', getWhitelistedAddress);
  yield takeLeading('ADD_WHITELIST_ADDRESS', addWhitelistAddress);
  yield takeLeading('DELETE_ADDRESS', deleteAddress);
  yield takeLeading('DELETE_MULTI_ADDRESSES', deleteMultiAddresses);
  yield takeLeading('TOGGLE_WHITELIST', toggleWhitelist);
  yield takeLeading('GET_REFERRALS', getReferrals);
  yield takeLeading('RESEND_EMAIL', resendEmail);
  yield takeLeading('GET_CRYPTO_RATES', getCryptoRates);
}


function postCall({ path, body }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ body, path }) {
  return axios
    .put(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
