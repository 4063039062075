import axios from "axios";
import EventBus from "eventing-bus";
import { put, all, takeLeading, call } from "redux-saga/effects";
import { setBalanceLoader, setCoinLoader, setHistoryLoader, setDepositLoader, setWithdrawLoader, setWithdrawHistoryLoader } from '../actions/Wallet';
import { setWalletBalance, setCoinWallet, setDepositHistory, setDepositFiatHistory, setWithdrawHistory, setWithdrawFiatHistory, setDepositDetails, withdrawEURLoader } from '../actions/Wallet';

function* getWalletBalance() {
  yield put(setBalanceLoader(true));
  const { error, response } = yield call(getCall, `/address`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setWalletBalance(response['data']['body']));
  yield put(setBalanceLoader(false));
}

function* getCoinWallet({ payload }) {
  yield put(setCoinLoader(true));
  const { error, response } = yield call(getCall, `/address/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setCoinWallet(response['data']['body']));
  yield put(setCoinLoader(false));
}

function* getDepositHistory({ payload }) {
  yield put(setDepositLoader(true));
  const { error, response } = yield call(postCall, { path: `/ledger`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setDepositHistory(response['data']['body']));
  yield put(setDepositLoader(false));
}

function* getDepositFiatHistory() {
  yield put(setHistoryLoader(true));
  const { error, response } = yield call(getCall, `/ledger/historyEUR/Deposit`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setDepositFiatHistory(response['data']['body']));
  yield put(setHistoryLoader(false));
}

function* getWithdrawHistory({ payload }) {
  yield put(setWithdrawHistoryLoader(true));
  const { error, response } = yield call(postCall, { path: `/ledger`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setWithdrawHistory(response['data']['body']));
  yield put(setWithdrawHistoryLoader(false));
}

function* getWithdrawFiatHistory() {
  yield put(setHistoryLoader(true));
  const { error, response } = yield call(getCall, `/ledger/historyEUR/Withdraw`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setWithdrawFiatHistory(response['data']['body']));
  yield put(setHistoryLoader(false));
}

function* withdrawalRequest({ payload }) {
  yield put(setWithdrawLoader(true));
  const { error, response } = yield call(postCall, { path: `/ledger/withdraw`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);

  yield put(setWithdrawLoader(false));
  yield call(getCall, `/address/${payload['symbol']}`);
  const { response: history } = yield call(postCall, { path: `/ledger`, body: { symbol: payload['symbol'], type: payload['type'] } });
  if (history) yield put(setWithdrawHistory(history['data']['body']));
}

function* verifyWithdrawal({ payload, history }) {
  const { error, response } = yield call(getCall, `/ledger/status/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
  history.push('/wallet/withdraw');
}

function* getDepositDetails() {
  const { error, response } = yield call(getCall, `/ledger/depositDetails`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(setDepositDetails(response['data']['body']));
}

function* withdrawEUR({ payload }) {
  const { error, response } = yield call(postCall, { path: `/ledger/withdrawEUR`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
  yield put(withdrawEURLoader(false));
}

function* actionWatcher() {
  yield takeLeading("GET_COIN_WALLET", getCoinWallet);
  yield takeLeading("VERIFY_WITHDRAWAL", verifyWithdrawal);
  yield takeLeading("GET_WALLET_BALANCE", getWalletBalance);
  yield takeLeading("GET_DESPOSIT_HISTORY", getDepositHistory);
  yield takeLeading("GET_DESPOSIT_FIAT_HISTORY", getDepositFiatHistory);
  yield takeLeading("GET_WITHDRAW_HISTORY", getWithdrawHistory);
  yield takeLeading("GET_WITHDRAW_FIAT_HISTORY", getWithdrawFiatHistory);
  yield takeLeading("WITHDRAWAL_REQUEST", withdrawalRequest);
  yield takeLeading("GET_DEPOSIT_DETAILS", getDepositDetails);
  yield takeLeading("WITHDRAW_EUR", withdrawEUR);
}


function postCall({ path, body }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ body, path }) {
  return axios
    .put(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
